const axios = require('axios').default;

module.exports = {
async listPaginate(page, client_id){
   let data = {"client_id": client_id}
   try {
      let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/brand/list/paginate?page=${page}`, data) 
      apiResponse = apiResponse.data.posts
      return apiResponse
   } catch (error) {
      return error
   }
},
async create(data){
   try {
      let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/brand/create`, data) 
      return apiResponse
    } catch (error) {
      return error
   }
},
async update(data){
   try {
      let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/brand/update`, data) 
      return apiResponse
    } catch (error) {
      return error
   }
},
async delete(data){
   try {
      let apiResponse = await axios.delete(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/brand/delete`, {
         headers: {},
         data: data,
       }) 
      return apiResponse
    } catch (error) {
      return error
   }
},
async upload_image(data){
   try {
      let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/brand/upload_image`, data) 
      return apiResponse
    } catch (error) {
      return error
   }
},
async findID(brand_id){
   let data = {"id":brand_id}
   try {
      const apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/brand/find/id`, data) 
      return apiResponse
    } catch (e) {
      return e
   }
},
}